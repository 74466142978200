import CategoryProduct from '../apis/product';

const state = {
  cart: [],
  products: [],
};

const actions = {
  getMenuProductList({ commit }, data) {
    return CategoryProduct.MenuProductList(data).then(response => {
      if (response.data.code == 200) {
        commit('getMenuProductMutationList', response.data.Result)
      }
      return response.data;
    })
  },

  getProductDetails({ commit }, data) {
    return CategoryProduct.RestaurantMenusItemDetails(data).then(response => {
      if (response.data.code == 200) {
        commit('getProductDetails', response.data.Result)
      }
      return response.data;
    })
  },

  restaurantMenusListData({ commit }, data) {
    return CategoryProduct.restaurantMenusList(data).then(response => {
      if (response.data.code == 200) {
        commit('getProductDetails', response.data.Result)
      }
      return response.data;
    })
  },

  addProductToCart(context, product) {
    if (product.quantity > 0) {
      const cartItem = context.state.cart.find(item => parseInt(item.item_id) === parseInt(product.item_id));
      let itemIndex = '';
      if (product.selectCustoizeID.length) {
        itemIndex = context.state.cart.findIndex((item) => {
          if (product.item_id == item.item_id) {
            if (product.selectCustoizeID) {
              if (item.selectCustoizeID.length > 0) {
                return item.selectCustoizeID.every(function (element) {
                  return product.selectCustoizeID.includes(element) && item.selectCustoizeID.length == product.selectCustoizeID.length;
                });
              } else { }
            }
          }
        });
      } else {
        itemIndex = context.state.cart.findIndex(item => (parseInt(item.item_id) === parseInt(product.item_id) && item.selectCustoizeID.length == 0));
      }
      if (!cartItem || itemIndex == '-1') {
        //Push product
        if (!cartItem && cartItem && cartItem.item_id === product.item_id) {
          product.itemQuntity = cartItem.quantity + product.quantity;
        } else {
          product.itemQuntity = product.quantity;
        }
        context.commit('pushProductToCart', product);
      } else {
        //Increment Item Quantity
        if (itemIndex === '') {
          itemIndex = context.state.cart.findIndex(item => parseInt(item.item_id) === parseInt(product.item_id));
          context.state.cart[itemIndex].quantity = cartItem.quantity + product.quantity;
          context.commit('cartQuantityUpdate', product)
        } else if (itemIndex >= 0) {
          // itemIndex = context.state.cart.findIndex(item => item.item_id === product.item_id);
          //context.state.cart[itemIndex].quantity = cartItem.quantity + product.quantity;
          context.state.cart[itemIndex].quantity += product.quantity;
          localStorage.setItem("cart", JSON.stringify(context.state.cart))
          context.commit('cartQuantityUpdate', product)
        }
      }
    } else {
      alert(this.$t("Product Quantity Not Available select other product"));
    }
  },

  removeProductFromCart(context, product) {
    context.commit('removeProductFromCartItem', product);
  },

  getRestaurantCategoryWithMenuData({ commit }, data) {
    return CategoryProduct.getRestaurantCategoryWithMenu(data).then(response => {
      if (response.data.code == 200) {
        // commit('getMenuProductList', response.data.Result)
        return response.data;
      }
      return response.data;
    })
  },

};

const mutations = {
  getMenuProductMutationList(state, products) {
    state.products = [...products]
  },

  getProductDetails(state, product) {
    state.product = [...product]
  },

  pushProductToCart(state, cartItem) {
    let quantity = 1;
    if (cartItem.quantity > 1) {
      quantity = cartItem.quantity;
    }
    state.cart.push({
      restaurant_id: cartItem.restaurant_id,
      item_id: cartItem.item_id,
      name: cartItem.item_name,
      price: cartItem.price,
      selected_type: cartItem.selected_type,
      quantity: quantity,
      itemTotalQuantity: cartItem.itemTotalQuantity,
      selectCustoizeID: cartItem.selectCustoizeID,
      itemQuntity: cartItem.itemQuntity,
      selectCustoizePrice: cartItem.selectCustoizePrice,
      item_type: cartItem.item_type,
      item_price: cartItem.item_price,
      image: cartItem.image,
      saveprice: cartItem.saveprice,
      item_tax: cartItem.item_tax,
      mrp: cartItem.mrp,
      packaging_charge: cartItem.packaging_charge
    })
    localStorage.setItem("cart", JSON.stringify(state.cart));
  },

  updateCart(state, product) {
    const cartItemIndex = state.cart.findIndex(item => parseInt(item.item_id) === parseInt(product.item_id));
    if (cartItemIndex != undefined) {
      state.cart[cartItemIndex].price = product.price;
      state.cart[cartItemIndex].item_price = product.price;
    }
    localStorage.setItem("cart", JSON.stringify(state.cart));
  },

  updateCuatomizeCart(state, product) {
    state.cart.forEach(item => {
      if (item.selectCustoizeID.length > 0) {
        let custIDIndex = item.selectCustoizeID.findIndex(item => parseInt(item) === parseInt(product.customisation_id));
        if (custIDIndex !== undefined) {
          item.selectCustoizePrice[custIDIndex] = product.price;
        }
      }
    });
    localStorage.setItem("cart", JSON.stringify(state.cart));
  },

  creatReorderCart(state, cartItem) {
    state.cart.push({
      restaurant_id: cartItem.restaurant_id,
      item_id: cartItem.item_id,
      name: cartItem.item_name,
      price: cartItem.price,
      selected_type: cartItem.selected_type,
      quantity: cartItem.quantity,
      itemTotalQuantity: cartItem.itemTotalQuantity,
      selectCustoizeID: cartItem.selectCustoizeID,
      itemQuntity: cartItem.itemQuntity,
      selectCustoizePrice: cartItem.selectCustoizePrice,
      item_type: cartItem.item_type,
      item_price: cartItem.item_price,
      image: cartItem.image,
      saveprice: cartItem.saveprice,
      item_tax: cartItem.item_tax,
      mrp: cartItem.mrp,
      packaging_charge: cartItem.packaging_charge
    });
    localStorage.setItem("cart", JSON.stringify(state.cart));
  },

  IncrementItemQuantity(state, cartItem) {
    state.cart[cartItem.cartItemIndex].name = (cartItem.product.item_name ? cartItem.product.item_name : cartItem.product.name);
    state.cart[cartItem.cartItemIndex].order = '';
    state.cart[cartItem.cartItemIndex].quantity++;
    localStorage.setItem("cart", JSON.stringify(state.cart));
  },

  decrementProductInventary(state, index) {
    state.cart[index].order = '';
    state.cart[index].quantity--;
    localStorage.setItem("cart", JSON.stringify(state.cart));
  },

  clearCartItems(state) {
    state.cart = [];
    localStorage.setItem("cart", JSON.stringify(state.cart));
  },

  removeProductFromCartItem(state, index) {
    state.cart = state.cart.filter((item, key) => {
      return key !== index;
    })
    localStorage.setItem("cart", JSON.stringify(state.cart));
  },

  customizeCartUpdate(state) {
    state.cart.forEach(item => {
      item.price = (item.item_price + item.selectCustoizePrice.reduce((partialSum, a) => partialSum + a, 0));
    });
    localStorage.setItem("cart", JSON.stringify(state.cart));
  },

  setCart(state, data) {
    state.cart = data;
  },

  cartQuantityUpdate(state, data) { }
};

const getters = {
  cart: state => state.cart,
  cartTotal(state, getters) {
    return getters.cartProducts.reduce((total, product) => total + product.price * product.quantity, 0)
  },
  products: state => state.products,
}

export const product = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}